import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Section from 'common/Section';
import { UmbracoPromoForm } from '@shared/types/umbraco';
import PromoFormComponent from '@promo-platform/promo-form';
import './PromoForm.scss';
import '@promo-platform/styles';

const PromoForm: FC<UmbracoPromoForm.IData> = ({ id, url, color }) =>
  url && id ? (
    <Section name="promo-form" color={color}>
      <PromoFormComponent id={id} url={url} />
    </Section>
  ) : null;

export const query = graphql`
  fragment FragmentPromoForm on TPromoForm {
    properties {
      url
      id
      color {
        label
      }
    }
    structure
  }
`;

export default PromoForm;
